import React, { useState } from "react";
import { m, AnimatePresence } from "framer-motion";
import { Container, Text } from "@atoms";
import { useAppState } from "@state";
import t from "@utils/t";
import classNames from "classnames";
import IntentionCardOneSided from "../molecules/IntentionCardOneSided";

const IntentionIntro = ({
  label,
  heading,
  description,
  steps,
  sectionState,
  cards,
  lang,
}) => {
  const [sectionOpen, setSectionOpen] = sectionState;
  const [hover, setHover] = useState(false);
  const [, dispatch] = useAppState();

  return (
    <AnimatePresence>
      {!sectionOpen && (
        <>
          <m.div
            className="min-h-[99vh] pt-24 text-white lg:pt-32"
            initial={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.6 }}
          >
            <Container>
              <m.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
              >
                {label && (
                  <span className="mb-6 block text-center font-neutral text-xs font-bold tracking-[0.35em]">
                    {label}
                  </span>
                )}
                {heading && (
                  <h2 className="mx-auto mb-8 max-w-lg text-center font-magilio text-h4 sm:text-h3">
                    {heading}
                  </h2>
                )}
              </m.div>

              <m.div
                className="mx-auto max-w-xl"
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
              >
                {description && (
                  <Text variant="lg" className="prose">
                    {description}
                  </Text>
                )}
                {steps?.length > 0 && (
                  <ul className="mt-10 flex flex-col divide-y-3 divide-black sm:flex-row sm:divide-y-0 sm:divide-x-3">
                    {steps?.map((step, i) => {
                      return (
                        <li
                          key={`step-${i + 1}--${step}`}
                          className="flex w-full flex-row items-center justify-start gap-3 py-4 text-left sm:w-1/3 sm:flex-col sm:justify-center sm:py-0 sm:px-4 sm:text-center"
                        >
                          <span className="flex aspect-square w-14 shrink-0 scale-90 items-center justify-center rounded-full bg-black font-magilio text-h5 leading-3 sm:mx-auto sm:scale-100">
                            <span className="translate-y-0.5">{i + 1}</span>
                          </span>
                          <span className="max-w-xs text-sm font-bold sm:text-xs">
                            {step}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </m.div>
            </Container>
          </m.div>

          <m.div
            className="relative inset-x-0 flex w-full translate-y-40 justify-center pt-8 sm:pt-0 tall:fixed tall:bottom-10 tall:xl:relative tall:xl:bottom-0"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0, transition: { delay: 0.5 } }}
            exit={{ position: "fixed" }}
            transition={{ duration: 0.6, ease: "linear" }}
          >
            <div className="h-28 pt-10 tall:h-auto tall:overflow-visible tall:pt-0">
              <m.button
                type="button"
                className="relative flex justify-center tall:h-auto"
                aria-label={t("View intention cards", lang)}
                onClick={() => {
                  setSectionOpen(true);
                  dispatch({ type: "viewIntentionCards" });
                }}
                onHoverStart={e => setHover(true)}
                onHoverEnd={e => setHover(false)}
                whileHover={{ y: "-0.5rem" }}
              >
                <div className="flex justify-center">
                  {cards?.map((card, i) => (
                    <IntentionCardOneSided
                      key={`intro-${card.uid}`}
                      {...card}
                      hoverState={[hover, setHover]}
                      i={i}
                      sectionState={[sectionOpen, setSectionOpen]}
                    />
                  ))}
                </div>
              </m.button>
            </div>
          </m.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default IntentionIntro;
