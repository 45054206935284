import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { m, transform, usePresence } from "framer-motion";

const IntentionCardOneSided = ({ artwork, hoverState, i }) => {
  const buildUrl = (url, params) =>
    `${url.replace(
      process.env.GATSBY_CDN_BASE_URL,
      process.env.GATSBY_IMGIX_BASE_URL
    )}?${Object.entries(params)
      .map(([key, value]) => `${key}=${value}`)
      .join("&")}`;

  const [backgroundImage, setBakgroundImage] = useState(null);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    const getDataBackgroundImage = async imageUrl => {
      const response = await fetch(buildUrl(imageUrl, { w: 1000, q: 80 }));
      setBakgroundImage(`url(${response.url})`);
    };
    getDataBackgroundImage(artwork?.url);
  }, []);

  const zIndex = transform([0, 3], [80, 0])(i).toFixed();
  const y = transform(
    [0, 3],
    hoverState ? ["0rem", "-2rem"] : ["0rem", "-1rem"]
  )(i);

  const [isPresent, safeToRemove] = usePresence();

  useEffect(() => {
    if (!isPresent) {
      setTrigger(true);
      setTimeout(safeToRemove, 700);
    }
  }, [isPresent]);

  return (
    <m.div
      className="absolute flex items-center justify-center"
      exit={{
        opacity: 0,
        scale: 2,
        position: "fixed",
        bottom: 0,
        width: "100vw",
        height: "100vh",
        y: 0,
        transition: {
          duration: 0.6,
          delay: 0.2,
          opacity: { delay: i !== 0 ? 0 : 1.5 },
        },
      }}
      style={{
        zIndex,
        y,
      }}
      transition={{ duration: 0.6, ease: "linear" }}
    >
      <div
        className={classNames({
          "scale-[70%] average:sm:scale-90 tall:scale-75 tall:sm:scale-100":
            trigger,
        })}
      >
        <m.div
          className="aspect-playing-card h-auto w-[191px] overflow-hidden rounded-lg bg-viridian shadow-lg"
          style={{
            backgroundImage,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          transition={{ duration: 0.6 }}
        />
      </div>
    </m.div>
  );
};

IntentionCardOneSided.defaultProps = {};

export default IntentionCardOneSided;
